import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice
} from '@reduxjs/toolkit'
import { SLICE_NAME } from './Selectors'
import localforage from 'localforage'
import { encryptTransform } from 'redux-persist-transform-encrypt/lib'
import {
  updateMobileOrPanAction,
  updateCurrentPasswordAction,
  updatePanAction,
  updateUsernameAction,
  updateNewUsernameAction,
  updatePasswordsAction,
  validateSSOActions,
  completeSSOActions,
  updateIdentifierTypeAction,
  updateResetPasswordOptionAction,
  generateAccessCodeActions,
  forgotUsernamePanMobileActions,
  createUsernamePanMobileActions,
  unlockAccountPanMobileActions,
  forgotPasswordPanMobileActions,
  validateAccessCodeActions,
  updateCustomerNameAction,
  switchAccountAction,
  clearUserDataAction,
  forgotPasswordValidateOtpActions,
  unlockAccountValidateOtpActions,
  forgotUsernameValidateOtpActions,
  createUsernameValidateOtpActions,
  resetDataAction,
  updateIsTermsAndConditionsAcceptedAction,
  checkLoginStatusByTokenActions,
  updateSelectedAccountAction,
  canLoginActions,
  accountActivationOtpValidateActions,
  updateEmailOrMobileAction
} from './Actions'
import { SALT } from '~/src/Configurations/env'

const INITIAL_STATE = {
  settlementType: 0,
  mobileOrPan: '',
  emailOrMobile: '',
  username: '',
  maskedEmail: '',
  maskedMobile: '',
  identifierType: '',
  password: '',
  accessCode: '',
  otp: '',
  pan: '',
  refId: '',
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  newUsername: '',
  isValidated: false,
  SSOID: '',
  ssoValidationResponse: {
    sessionId: '',
    refreshToken: null,
    authToken: null,
    metadata: null
  },
  ssoCompleteResponse: { redirectURL: '' },
  resetPasswordAfterUnlocking: '',
  validateAccessCode: {},
  customerName: '',
  isAuthenticatedFlow: false,
  forgotPasswordValidateOtp: {},
  unlockAccountValidateOtp: {},
  forgotUsernameValidateOtp: {},
  createUsernameValidateOtp: {},
  isTermsAndConditionsAccepted: false,
  selectedAccount: {}
}

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    resetUserData: (state, action) => {
      state.validateAccessCode = null
      state.mobileOrPan = ''
      state.username = ''
      state.maskedEmail = ''
      state.maskedMobile = ''
      state.identifierType = ''
      state.password = ''
      state.accessCode = ''
      state.otp = ''
      state.pan = ''
      state.refId = ''
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(resetDataAction, (state, { payload }) => {
      for (const value of payload) {
        state[value] = (INITIAL_STATE as any)[value]
      }
    })
    builder.addCase(updateUsernameAction, (state, { payload }) => {
      state.username = payload
    })
    builder.addCase(updateEmailOrMobileAction, (state, { payload }) => {
      state.emailOrMobile = payload
    })
    builder.addCase(updateMobileOrPanAction, (state, { payload }) => {
      state.mobileOrPan = payload
    })
    builder.addCase(updateIdentifierTypeAction, (state, { payload }) => {
      state.identifierType = payload
    })
    builder.addCase(updateResetPasswordOptionAction, (state, { payload }) => {
      state.resetPasswordAfterUnlocking = payload
    })
    builder.addCase(updateCurrentPasswordAction, (state, { payload }) => {
      state.password = payload
    })
    builder.addCase(updatePasswordsAction, (state, { payload }) => {
      state.newPassword = payload.newPassword
      state.confirmPassword = payload.confirmNewPassword
    })
    builder.addCase(updatePanAction, (state, { payload }) => {
      state.pan = payload
    })
    builder.addCase(updateNewUsernameAction, (state, { payload }) => {
      state.newUsername = payload
    })
    builder.addCase(updateCustomerNameAction, (state, { payload }) => {
      state.customerName = payload
    })
    builder.addCase(
      updateIsTermsAndConditionsAcceptedAction,
      (state, { payload }) => {
        state.isTermsAndConditionsAccepted = payload
      }
    )
    builder.addCase(switchAccountAction, state => {
      state.mobileOrPan = ''
      state.username = ''
      state.identifierType = ''
      state.customerName = ''
      state.settlementType = ''
    })
    builder.addCase(clearUserDataAction, state => {
      state.mobileOrPan = ''
      state.username = ''
      state.identifierType = ''
    })
    builder.addCase(validateSSOActions.success, (state, { payload }) => {
      state.ssoValidationResponse = payload.data
      state.isValidated = true
      if (payload.data.authToken) {
        state.isAuthenticatedFlow = true
      }
    })
    builder.addCase(
      accountActivationOtpValidateActions.success,
      (state, { payload }) => {
        const { refId } = payload.data
        state.refId = refId
      }
    )
    builder.addCase(generateAccessCodeActions.success, (state, { payload }) => {
      state.refId = payload.data.refId
      state.maskedMobile = payload.data.mobileNumber
      state.maskedEmail = payload.data.email
    })
    builder.addCase(canLoginActions.error, (state, { payload }) => {
      if (payload.data) {
        const { otpResponse } = payload.data
        const { refId, mobileNumber, email } = otpResponse
        state.refId = refId
        state.maskedMobile = mobileNumber
        state.maskedEmail = email
      }
    })
    builder.addCase(
      createUsernamePanMobileActions.success,
      (state, { payload }) => {
        state.refId = payload.data.refId
        state.maskedMobile = payload.data.mobile
        state.maskedEmail = payload.data.email
      }
    )
    builder.addCase(
      unlockAccountPanMobileActions.success,
      (state, { payload }) => {
        state.refId = payload.data.refId
        state.maskedMobile = payload.data.mobile
        state.maskedEmail = payload.data.email
      }
    )
    builder.addCase(
      forgotPasswordPanMobileActions.success,
      (state, { payload }) => {
        state.refId = payload.data.refId
        state.maskedMobile = payload.data.mobile
        state.maskedEmail = payload.data.email
      }
    )
    builder.addCase(
      forgotUsernamePanMobileActions.success,
      (state, { payload }) => {
        state.refId = payload.data.refId
        state.maskedMobile = payload.data.mobile
        state.maskedEmail = payload.data.email
      }
    )
    builder.addCase(validateAccessCodeActions.success, (state, { payload }) => {
      state.validateAccessCode = payload.data
    })
    builder.addCase(completeSSOActions.success, (state, { payload }) => {
      state.ssoCompleteResponse = payload.data
    })
    builder.addCase(
      forgotPasswordValidateOtpActions.success,
      (state, { payload }) => {
        state.forgotPasswordValidateOtp = payload.data
      }
    )
    builder.addCase(
      unlockAccountValidateOtpActions.success,
      (state, { payload }) => {
        state.unlockAccountValidateOtp = payload.data
      }
    )
    builder.addCase(
      forgotUsernameValidateOtpActions.success,
      (state, { payload }) => {
        state.forgotUsernameValidateOtp = payload.data
      }
    )
    builder.addCase(
      createUsernameValidateOtpActions.success,
      (state, { payload }) => {
        state.createUsernameValidateOtp = payload.data
      }
    )
    builder.addCase(
      checkLoginStatusByTokenActions.success,
      (state, { payload }) => {
        state.validateAccessCode = payload.data
      }
    )
    builder.addCase(updateSelectedAccountAction, (state, { payload }) => {
      const { settlementType = 0 } = payload as any
      state.selectedAccount = payload
      state.settlementType = settlementType
    })
  }
}

const slice = createSlice(sliceOptions)

export const { resetUserData } = slice.actions
export const journeyPersistConfig = {
  key: SLICE_NAME,
  version: 1,
  storage: localforage,
  whitelist: [
    'mobileOrPan',
    'username',
    'identifierType',
    'customerName',
    'settlementType'
  ],
  transforms: [
    encryptTransform({
      secretKey: SALT,
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
}

export default slice.reducer
